import { Product } from "../types";

// TODO: Change this to a real API call
export const products: Product[] = [
    {
        slug: 'xill4',
        name: 'Xill4',
        description: "The Xill 4 is the industry's most advanced low-code and data-driven content migration platform. That enables us to co-create and perform (complex) migrations in a fast, reliable, compliant and cost-effective way.",
        link: "/products/xill4",
        keywords: [
            "migration builder", "xill", "xill3", "xill4"
        ],
        links: {
            documentation: "https://docs.xill.io/",
            releaseLatest: "https://dl.cloudsmith.io/s4ithSLtzxTVODZ7/xillio/xill4/raw/names/Xill4/versions/latest/Xill4.zip",
            accelerators: "/accelerators"
        },
    },
    {
        slug: 'link-redirector',
        name: 'Link Redirector',
        description: "Migrate without obstructions",
        link: "/products/link-redirector",
        keywords: [
            "link redirector", "broken links"
        ],
        links: {
            documentation: "https://docs.xill.io/link/docs/introduction",
            releaseLatest: "https://docs.xill.io/link/releases"
        },
    },
    {
        slug: 'connectivity-app',
        name: 'Connection Checker',
        description: "Check the validity of your MongoDB, SharePoint, and Azure credentials.",
        link: "/products/connectivity-app",
        keywords: [
            "connectivity", "connection", "check"
        ],
        links: {
            documentation: "https://github.com/xillio/connectivity-app/blob/master/README.md",
            releaseLatest: "https://dl.cloudsmith.io/public/xillio/connectivity-app/raw/names/connectivityApp/versions/1.0.1/connectivity_20_01.zip"
        },
    },
    {
        slug: 'content-quality-app',
        name: 'Content Quality',
        description: "Calculates a content quality score (0% to 100%) based on various weighted metrics, helping users identify improvement areas and demonstrate compliance.",
        link: "/products/content-quality-app",
        keywords: [
            "content", "quality"
        ],
        links: {
            documentation: "https://github.com/xillio/content-quality-app/blob/master/README.md",
            releaseLatest: "https://dl.cloudsmith.io/public/xillio/content-quality-app/raw/names/contentQuality/versions/1.0.0/cq-app.zip"
        },
    }
]
